<template>
  <v-container max-width-1400 full-width ma-auto pa-3>
    <v-layout row wrap align-center background>
      <v-flex xs12 sm6 md5 lg4>
        <v-img
          :aspect-ratio="1"
          contain
          class="ma-auto"
          position="center center"
          :src="image"
          :lazy-src="image"
          max-width="300px"
        ></v-img>
      </v-flex>

      <v-flex xs12 sm6>
        <p
          class="graphik-bold font-22 line-height-35 secondary--text mb-4"
        >{{ $ml.get('content_benefits_step_1') }}</p>


        <p
          class="graphik-light font-19 line-height-35 secondary--text mb-4"
        >{{ $ml.get('content_benefits_step_1_description') }}</p>

        <v-btn 
          color="pantene"
          depressed dark large
          :loading="g_isLoading"
          class="normalcase graphik-bold-italic ma-0 border-radius-5"
          @click="$goTo('/nosotros', 'goto_about')"
        >{{ $ml.get('general_know_more') }} <v-icon dark small class="ml-2">arrow_forward</v-icon></v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'ContentBenefits',
  data () {
    return {
      image: require('@/assets/img/images/thank_you.png')
    }
  },
}
</script>

<style>
  .background {
    background-color: #FCFBFF !important;
  }
</style>
